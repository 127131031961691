import {
  Checkbox,
  CheckboxProps,
  Combobox,
  createDatePickerValidator,
  createForm,
  DatePicker,
  Divider,
  Flex,
  Heading,
  Select,
  Text,
  TextInput,
  TextInputProps,
} from '@applyboard/crystal-ui'
import { find, get, isEqual } from 'lodash'
import {
  COUNTRY_NAMES,
  CountryIsoCode,
} from 'schools-domain-backend-utils/dist/common-types/country'
import { GENDER_NAMES, GenderCode } from 'schools-domain-backend-utils/dist/common-types/gender'
import { RawApplicationResponse, useUpdateApplication } from '../../../hooks'
import { GenericError } from '../../../utils'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { StudentApplication } from '../types'
import { useApplicationFormContext } from './ApplicationForm'
import { useGetDestinationCountry } from '../../../hooks/useGetDestinationCountry'
import { useEffect, useState, ComponentType } from 'react'
import { getFilesOfType } from './utils'
import { DestinationCountryStatus, DocumentTags } from '../../../utils/enums'
import { FileData } from 'applications-types-lib'
import { nanoid } from 'nanoid'
import { FileUploadField } from './FileUploadField'

type PersonalInformationFormFields = {
  passportNumber: string
  passportExpiryDate: string
  passportFiles: Array<{
    id: string
    file: File
  }>
  immigrationStatus: string
  immigrationFiles: Array<{
    id: string
    file: File
  }>
  givenName: string
  middleName: string
  familyName: string

  dateOfBirth: string
  nationality: string
  gender: string
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
  mailingAddressCountry: string
  mailingAddressLine1: string
  mailingAddressLine2: string
  mailingAddressCity: string
  mailingAddressProvince: string
  mailingAddressPostalCode: string
  isMailingAddressResidentialAddress: boolean
}

const UPLOAD_LIMIT = 1

const { Form, Field, useFieldValues, useSetFieldValues } =
  createForm<PersonalInformationFormFields>()

type PersonalInformationTabProps = {
  disabled?: boolean
  application: StudentApplication
  onSubmit?: () => void
  onSuccess: (response?: RawApplicationResponse) => void
  onError: (err: GenericError) => void
  updateApplication: ReturnType<typeof useUpdateApplication>['updateApplication']
  formId: string
}

export function PersonalInformationTab(props: PersonalInformationTabProps) {
  const { resetFiles, pendingFileUploadState } = useApplicationFormContext()
  const { destinationCountryName } = useGetDestinationCountry()

  useEffect(() => {
    resetFiles(
      getFilesOfType(
        [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
        props.application?.attributes?.files as FileData,
      ),
    )
  }, [props.application?.attributes?.files, resetFiles])

  const activePassport = find(
    props.application?.attributes?.files,
    itm =>
      (itm?.type as unknown as DocumentTags | undefined) === DocumentTags.PASSPORT &&
      !!itm?.activeRecord,
  )
  const passportInfo = activePassport?.sectionReference
    ? get(
        props.application?.attributes?.statusAndCitizenship?.passports,
        activePassport?.sectionReference,
      )
    : undefined
  const [passportId] = useState(activePassport?.sectionReference || nanoid())

  return (
    <Form
      id={props.formId}
      defaultValues={{
        passportNumber: passportInfo?.passportNumber || '',
        passportExpiryDate: passportInfo?.passportExpiry
          ? convertTimelessDateStrToLocalDate(passportInfo.passportExpiry).toISOString()
          : '',
        passportFiles: [],
        immigrationStatus:
          props.application?.attributes?.statusAndCitizenship?.destinationCountryStatus || '',
        immigrationFiles: [],
        givenName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.givenName ||
          '',
        middleName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.middleName || '',
        familyName:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.familyName || '',

        dateOfBirth: props.application?.attributes?.personalInformation?.basicPersonalInformation
          ?.dateOfBirth
          ? convertTimelessDateStrToLocalDate(
              props.application.attributes.personalInformation?.basicPersonalInformation
                ?.dateOfBirth,
            ).toISOString()
          : '',
        nationality:
          props.application?.attributes?.personalInformation?.basicPersonalInformation
            ?.nationality || '',
        gender:
          props.application?.attributes?.personalInformation?.basicPersonalInformation?.gender ||
          '',
        residentialAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.country || '',
        residentialAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine1 || '',
        residentialAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.addressLine2 || '',
        residentialAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress?.city ||
          '',
        residentialAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.province || '',
        residentialAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.residentialAddress
            ?.postalCode || '',
        isMailingAddressResidentialAddress: areAddressesEqual({
          application: props.application,
        }),
        mailingAddressCountry:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.country ||
          '',
        mailingAddressLine1:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine1 || '',
        mailingAddressLine2:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.addressLine2 || '',
        mailingAddressCity:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.city || '',
        mailingAddressProvince:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress?.province ||
          '',
        mailingAddressPostalCode:
          props.application?.attributes?.personalInformation?.addresses?.mailingAddress
            ?.postalCode || '',
      }}
      onSubmit={data => {
        if (props.disabled) {
          props.onSuccess()
        } else {
          props.updateApplication(
            {
              attributes: {
                personalInformation: {
                  basicPersonalInformation: {
                    givenName: data?.givenName,
                    middleName: data?.middleName,
                    familyName: data?.familyName,

                    dateOfBirth: data?.dateOfBirth?.substring(0, 10),
                    nationality: (data?.nationality as CountryIsoCode) || null,
                    gender: (data?.gender as GenderCode) || null,
                  },
                  addresses: {
                    residentialAddress: {
                      country: data.residentialAddressCountry as CountryIsoCode,
                      addressLine1: data.residentialAddressLine1,
                      addressLine2: data.residentialAddressLine2,
                      city: data.residentialAddressCity,
                      province: data.residentialAddressProvince,
                      postalCode: data.residentialAddressPostalCode,
                    },
                    mailingAddress: {
                      country: data.mailingAddressCountry as CountryIsoCode,
                      addressLine1: data.mailingAddressLine1,
                      addressLine2: data.mailingAddressLine2,
                      city: data.mailingAddressCity,
                      province: data.mailingAddressProvince,
                      postalCode: data.mailingAddressPostalCode,
                    },
                  },
                },
                statusAndCitizenship: {
                  destinationCountryStatus: data.immigrationStatus as DestinationCountryStatus,
                  passports: {
                    [passportId]: {
                      passportNumber: data.passportNumber,
                      passportExpiry: data.passportExpiryDate?.substring(0, 10),
                    },
                  },
                },
              },
              files: pendingFileUploadState,
            },
            {
              onSuccess: response => {
                resetFiles(
                  getFilesOfType(
                    [DocumentTags.PASSPORT, DocumentTags.DESTINATION_COUNTRY_STATUS],
                    response.data?.attributes?.files as FileData,
                  ),
                )
                props.onSuccess(response)
              },
              onError: props.onError,
            },
          )
        }
      }}
    >
      <PersonalInformationTabFields
        disabled={props.disabled}
        application={props.application}
        passportId={passportId}
        destinationCountryName={destinationCountryName}
      />
    </Form>
  )
}

type PersonalInformationFormFieldsProps = {
  disabled?: boolean
  application: StudentApplication
  destinationCountryName: string
  passportId: string
}

function PersonalInformationTabFields(props: PersonalInformationFormFieldsProps) {
  const {
    passportFiles,
    immigrationFiles,
    immigrationStatus,
    isMailingAddressResidentialAddress,
    residentialAddressCountry,
    residentialAddressLine1,
    residentialAddressLine2,
    residentialAddressCity,
    residentialAddressProvince,
    residentialAddressPostalCode,
  } = useFieldValues([
    'passportFiles',
    'immigrationFiles',
    'immigrationStatus',
    'isMailingAddressResidentialAddress',
    'residentialAddressCountry',
    'residentialAddressLine1',
    'residentialAddressLine2',
    'residentialAddressCity',
    'residentialAddressProvince',
    'residentialAddressPostalCode',
  ])
  const setFieldValues = useSetFieldValues()
  const maxDate = new Date()
  const minDate = new Date(1900, 0, 1)
  maxDate.setFullYear(maxDate.getFullYear() + 10)
  minDate.setFullYear(minDate.getFullYear() - 3)

  const [hasValidVisa, setHasValidVisa] = useState(
    immigrationStatus !== 'NO_STATUS' && !!immigrationStatus,
  )
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const immigrationStatusList = [
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.NO_STATUS,
      label: "I don't have this",
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.STUDY_PERMIT,
      label: 'Study permit',
    },
    { key: crypto.randomUUID(), value: DestinationCountryStatus.WORK_PERMIT, label: 'Work permit' },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.VISITOR,
      label: 'Visitor/Tourist visa',
    },
    {
      key: crypto.randomUUID(),
      value: DestinationCountryStatus.OTHER,
      label: 'Other',
    },
  ]

  const datePickerValidator = createDatePickerValidator(minDate, maxDate, 'day')

  return (
    <>
      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            ✈️ Step 1: Provide your passport
          </Heading>
        </Flex>
        <Flex gap={4} direction="column" basis="100%">
          <Field
            as={FileUploadField}
            allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
            application={props.application}
            disabled={props.disabled}
            fileLimit={UPLOAD_LIMIT}
            fileType={DocumentTags.PASSPORT}
            label={
              <Text variant={'bodyM'}>
                Your passport is required to proceed with the application. We'll use it to:
                <ul>
                  <li>Verify your identity</li>
                  <li>Pre-fill application fields (e.g., name, date of birth, gender)</li>
                </ul>
              </Text>
            }
            name={`passportFiles`}
            onRemove={(id: string) =>
              setFieldValues({ passportFiles: passportFiles.filter(file => file.id !== id) })
            }
            section={props.passportId}
            showHistory={!!props.disabled}
            helpText={`Add your passport document below, supported file formats: JPG, JPEG, PDF, PNG, max
                number of files: ${UPLOAD_LIMIT}`}
            validate={value => {
              const observableFiles = getObservableFiles({
                fileType: DocumentTags.PASSPORT,
                sectionReference: props.passportId,
              })

              if (Object.keys(observableFiles).length > UPLOAD_LIMIT) {
                return `This field has a file limit of ${UPLOAD_LIMIT}.`
              }

              if (!(Object.keys(observableFiles).length || value.length)) {
                return 'This field is required'
              }
              return true
            }}
          />
        </Flex>

        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput as ComponentType<TextInputProps>}
            label="Passport number"
            name="passportNumber"
            disabled={props.disabled}
            required={!props.disabled ? 'Passport number is required' : false}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={DatePicker}
            label="Passport expiry date"
            name="passportExpiryDate"
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            disabled={props.disabled}
            required={!props.disabled ? 'Passport expiry date is required' : false}
          />
        </Flex.Item>

        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput as ComponentType<TextInputProps>}
            label="Given name"
            name="givenName"
            disabled={props.disabled}
            required={!props.disabled ? 'Given name is required' : false}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput as ComponentType<TextInputProps>}
            label="Family name (if applicable)"
            name="familyName"
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput as ComponentType<TextInputProps>}
            label="Middle name"
            name="middleName"
            disabled={props.disabled}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Select}
            label="Gender"
            name="gender"
            appearance="styled"
            disabled={props.disabled}
            required={!props.disabled ? 'Gender is required' : false}
          >
            {Object.entries(GENDER_NAMES).map(([genderCode, genderName]) => (
              <Select.Option key={genderCode} label={genderName} value={genderCode} />
            ))}
          </Field>
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={DatePicker}
            label="Date of birth"
            name="dateOfBirth"
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            disabled={props.disabled}
            required={!props.disabled ? 'Date of birth is required' : false}
            validate={datePickerValidator}
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Combobox}
            label="Nationality"
            name="nationality"
            size="md"
            placeholder="Select"
            disabled={props.disabled}
            required={!props.disabled ? 'Nationality is required' : false}
          >
            {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
              <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
            ))}
          </Field>
        </Flex.Item>
      </Flex>

      <Divider />

      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            ✈️ Step 2: Provide additional information
          </Heading>
        </Flex>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            <span aria-hidden>🏢</span> Residential Address
          </Heading>
        </Flex>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Combobox}
            label="Country"
            name="residentialAddressCountry"
            size="md"
            placeholder="Select"
            disabled={props.disabled}
            required={!props.disabled ? 'Country is required' : false}
            onChange={value => {
              if (isMailingAddressResidentialAddress) {
                setFieldValues({
                  ...copyResidentialAddress({
                    residentialAddressCountry,
                    residentialAddressLine1,
                    residentialAddressLine2,
                    residentialAddressCity,
                    residentialAddressProvince,
                    residentialAddressPostalCode,
                  }),
                  mailingAddressCountry: value as string,
                })
              }
            }}
          >
            {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
              <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
            ))}
          </Field>
        </Flex.Item>
        <Flex pb={6} gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
          <Flex.Item basis={{ xs: '100%' }}>
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              label="Address line 1"
              name="residentialAddressLine1"
              disabled={props.disabled}
              required={!props.disabled ? 'Address is required' : false}
              helpText="E.g. Street address"
              onChange={value => {
                if (isMailingAddressResidentialAddress) {
                  setFieldValues({
                    ...copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                    mailingAddressLine1: value as string,
                  })
                }
              }}
            />
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%' }}>
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              label="Address line 2"
              name="residentialAddressLine2"
              disabled={props.disabled}
              helpText="E.g. Apartment, suite, unit"
              onChange={value => {
                if (isMailingAddressResidentialAddress) {
                  setFieldValues({
                    ...copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                    mailingAddressLine2: value as string,
                  })
                }
              }}
            />
          </Flex.Item>
          <Flex basis="100%" gap={4}>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput as ComponentType<TextInputProps>}
                label="Province/state/region"
                name="residentialAddressProvince"
                disabled={props.disabled}
                required={!props.disabled ? 'Province/state/region is required' : false}
                onChange={value => {
                  if (isMailingAddressResidentialAddress) {
                    setFieldValues({
                      ...copyResidentialAddress({
                        residentialAddressCountry,
                        residentialAddressLine1,
                        residentialAddressLine2,
                        residentialAddressCity,
                        residentialAddressProvince,
                        residentialAddressPostalCode,
                      }),
                      mailingAddressProvince: value as string,
                    })
                  }
                }}
              />
            </Flex.Item>
            <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
              <Field
                as={TextInput as ComponentType<TextInputProps>}
                label="City/town"
                name="residentialAddressCity"
                disabled={props.disabled}
                required={!props.disabled ? 'City/town is required' : false}
                onChange={value => {
                  if (isMailingAddressResidentialAddress) {
                    setFieldValues({
                      ...copyResidentialAddress({
                        residentialAddressCountry,
                        residentialAddressLine1,
                        residentialAddressLine2,
                        residentialAddressCity,
                        residentialAddressProvince,
                        residentialAddressPostalCode,
                      }),
                      mailingAddressCity: value as string,
                    })
                  }
                }}
              />
            </Flex.Item>
          </Flex>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Field
              as={TextInput as ComponentType<TextInputProps>}
              label="Postal/zip code"
              name="residentialAddressPostalCode"
              disabled={props.disabled}
              onChange={value => {
                if (isMailingAddressResidentialAddress) {
                  setFieldValues({
                    ...copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                    mailingAddressPostalCode: value as string,
                  })
                }
              }}
            />
          </Flex.Item>
        </Flex>

        <Flex direction={'column'}>
          <Flex basis={'100%'}>
            <Heading variant="titleS" level={3}>
              <span aria-hidden>✉️</span> Full Mailing Address
            </Heading>
          </Flex>

          <Flex basis={'100%'}>
            <Field
              as={Checkbox as ComponentType<CheckboxProps>}
              name="isMailingAddressResidentialAddress"
              label="Mailing address is the same as residential address"
              disabled={props.disabled}
              onChange={value => {
                if (!value) {
                  setFieldValues({
                    mailingAddressCountry: '',
                    mailingAddressLine1: '',
                    mailingAddressLine2: '',
                    mailingAddressCity: '',
                    mailingAddressProvince: '',
                    mailingAddressPostalCode: '',
                  })
                } else {
                  setFieldValues(
                    copyResidentialAddress({
                      residentialAddressCountry,
                      residentialAddressLine1,
                      residentialAddressLine2,
                      residentialAddressCity,
                      residentialAddressProvince,
                      residentialAddressPostalCode,
                    }),
                  )
                }
              }}
            />
          </Flex>

          {!isMailingAddressResidentialAddress ? (
            <>
              <Flex basis={'100%'} pb={4}>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={Combobox}
                    label="Country"
                    name="mailingAddressCountry"
                    size="md"
                    placeholder="Select"
                    disabled={props.disabled}
                    required={!props.disabled ? 'Country is required' : false}
                  >
                    {Object.entries(COUNTRY_NAMES).map(([countryCode, countryName]) => (
                      <Combobox.Option key={countryCode} label={countryName} value={countryCode} />
                    ))}
                  </Field>
                </Flex.Item>
              </Flex>

              <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
                <Flex.Item basis={{ xs: '100%' }}>
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Address line 1"
                    name="mailingAddressLine1"
                    disabled={props.disabled}
                    required={!props.disabled ? 'Address is required' : false}
                    helpText="E.g. Street address"
                  />
                </Flex.Item>
                <Flex.Item basis={{ xs: '100%' }}>
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Address line 2"
                    name="mailingAddressLine2"
                    disabled={props.disabled}
                    helpText="E.g. Apartment, suite, unit"
                  />
                </Flex.Item>
                <Flex basis="100%" gap={4}>
                  <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                    <Field
                      as={TextInput as ComponentType<TextInputProps>}
                      label="Province/state/region"
                      name="mailingAddressProvince"
                      disabled={props.disabled}
                      required={!props.disabled ? 'Province/state/region is required' : false}
                    />
                  </Flex.Item>
                  <Flex.Item basis={{ xs: 'calc(50% - 8px)' }}>
                    <Field
                      as={TextInput as ComponentType<TextInputProps>}
                      label="City/town"
                      name="mailingAddressCity"
                      disabled={props.disabled}
                      required={!props.disabled ? 'City/town is required' : false}
                    />
                  </Flex.Item>
                </Flex>
                <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
                  <Field
                    as={TextInput as ComponentType<TextInputProps>}
                    label="Postal/zip code"
                    name="mailingAddressPostalCode"
                    disabled={props.disabled}
                  />
                </Flex.Item>
              </Flex>
            </>
          ) : null}
        </Flex>
      </Flex>

      <Divider />

      <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap pt={4} pb={4}>
        <Flex basis="100%">
          <Heading variant="titleS" level={3}>
            ✈️ Step 3: Provide destination status
          </Heading>
        </Flex>
        <Flex basis="100%" direction="column">
          <Heading variant="titleS" level={3}>
            🌎 {props.destinationCountryName || 'Destination Country'} Status
          </Heading>
          <Text variant="bodyS">
            Tell us below your current visa/immigration status in {props.destinationCountryName}.
          </Text>
        </Flex>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={Select}
            label="Status"
            name="immigrationStatus"
            appearance="styled"
            disabled={props.disabled}
            required={!props.disabled ? 'Status is required' : false}
            onChange={v => {
              const fileIds = Object.keys(
                getObservableFiles({ fileType: DocumentTags.DESTINATION_COUNTRY_STATUS }),
              )
              if ((!v || v === 'NO_STATUS') && !!fileIds.length) {
                fileIds.forEach(id => addPendingDelete(id))
              }
              setHasValidVisa(!!v && v !== 'NO_STATUS')
            }}
          >
            {immigrationStatusList.map(status => (
              <Select.Option key={status.key} label={status.label} value={status.value} />
            ))}
          </Field>
        </Flex.Item>
        {hasValidVisa ? (
          <Flex gap={4} direction="column" basis="100%">
            <Field
              as={FileUploadField}
              allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
              application={props.application}
              disabled={props.disabled || !hasValidVisa}
              fileType={DocumentTags.DESTINATION_COUNTRY_STATUS}
              label={
                <Text variant={'bodyM'}>
                  <h3>Provide Study Permit</h3>
                  Please provide a clear and legible copy of your study permit. Ensure that all
                  relevant information, including your name, certificate number, and exam date, is
                  clearly visible.
                </Text>
              }
              helpText={`Supported file formats: JPG, JPEG, PDF, PNG, max number of files: ${UPLOAD_LIMIT}`}
              name={`immigrationFiles`}
              onRemove={(id: string) =>
                setFieldValues({
                  immigrationFiles: immigrationFiles.filter(file => file.id !== id),
                })
              }
              section={`destinationCountryStatus`}
              validate={value => {
                const observableFiles = getObservableFiles({
                  fileType: DocumentTags.DESTINATION_COUNTRY_STATUS,
                  sectionReference: 'destinationCountryStatus',
                })

                if (hasValidVisa && !(Object.keys(observableFiles).length || value.length)) {
                  return 'This field is required'
                }
                return true
              }}
              showHistory={false}
            />
          </Flex>
        ) : null}
      </Flex>
    </>
  )
}

type areAddressesEqualParams = {
  application: StudentApplication
}

function copyResidentialAddress({
  residentialAddressCountry,
  residentialAddressLine1,
  residentialAddressLine2,
  residentialAddressCity,
  residentialAddressProvince,
  residentialAddressPostalCode,
}: {
  residentialAddressCountry: string
  residentialAddressLine1: string
  residentialAddressLine2: string
  residentialAddressCity: string
  residentialAddressProvince: string
  residentialAddressPostalCode: string
}) {
  return {
    mailingAddressCountry: residentialAddressCountry,
    mailingAddressLine1: residentialAddressLine1,
    mailingAddressLine2: residentialAddressLine2,
    mailingAddressCity: residentialAddressCity,
    mailingAddressProvince: residentialAddressProvince,
    mailingAddressPostalCode: residentialAddressPostalCode,
  }
}

function areAddressesEqual(params: areAddressesEqualParams) {
  if (
    !params.application?.attributes?.personalInformation?.addresses ||
    isEqual(
      params.application.attributes?.personalInformation?.addresses?.residentialAddress,
      params.application.attributes?.personalInformation?.addresses?.mailingAddress,
    )
  ) {
    return true
  }
  return false
}
