import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import { Button, ButtonLink, Flex } from '@applyboard/crystal-ui'
import { NavBarItem } from './NavBarItem'
import { MenuItemsProps } from './types'
import { useAuthManager } from '../../hooks'
import { LogoutOutlineIcon } from '@applyboard/ui-icons'

export function DesktopSidebar(props: { open: boolean; menuItems: Array<MenuItemsProps> }) {
  const { signout } = useAuthManager()

  return (
    <Flex hideBelow="sm">
      <Flex.Item as="aside" grow={0} shrink={0} basis={64}>
        <Flex hideBelow="sm">
          <DesktopSidebarContainer open={props.open}>
            <Flex direction="column" grow={1} p={2}>
              <Flex grow={1} as="nav" aria-label="Sidebar Navigation" direction="column" gap={2}>
                {props.menuItems.map(item => (
                  <NavBarItem key={item.id} {...item}>
                    {props.open ? item.label : undefined}
                  </NavBarItem>
                ))}
              </Flex>
              <Button
                leadIcon={LogoutOutlineIcon}
                aria-label="Sign Out"
                emphasis="transparent"
                intent="primary"
                onClick={() => signout()}
              >
                {props.open ? 'Sign Out' : undefined}
              </Button>
            </Flex>
          </DesktopSidebarContainer>
        </Flex>
      </Flex.Item>
      {props.open && createPortal(<SidebarOverlay />, document.body)}
    </Flex>
  )
}

const DesktopSidebarContainer = styled.div<{ open: boolean }>(props => ({
  display: 'flex',
  borderRight: `1px solid ${props.theme.colors.borderRegular}`,
  zIndex: props.open ? 1720 : 'auto', // AB z-index drawer
  backgroundColor: '#FFF',
  marginTop: '64px',
  minHeight: 'calc(100vh - 64px)',
  transition: 'width 200ms ease-in-out',
  borderRadius: props.open ? '0 16px 16px 0' : 0,
  overflow: 'hidden',
  width: props.open ? 'auto' : 64,
  a: {
    paddingInline: props.open ? '11px' : 0,
    '> div': {
      justifyContent: props.open ? 'flex-start' : 'center',
    },
  },
}))

const SidebarOverlay = styled.div(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.colors.iconPrimaryStark,
  opacity: 0.31,
  zIndex: 1710,
}))
