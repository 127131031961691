import { ApiStudentRequest, request } from '../utils/request'
import { useQuery } from '@tanstack/react-query'

export type RawSpaConfigResponse = {
  auth: {
    issuer: string
    clientId: string
    redirectUri: string
  }
}

export function useGetSpaConfig() {
  const { isLoading, data } = useQuery({
    queryKey: ['spaConfig'],
    queryFn: async () => {
      const response = await request<RawSpaConfigResponse>(
        new ApiStudentRequest(`/spa-config`, { method: 'GET' }),
        {
          isExpectedResponse: (res): res is RawSpaConfigResponse => res,
        },
      )
      return response
    },
    enabled: true,
    refetchOnWindowFocus: false,
  })

  return {
    isLoading: isLoading,
    config: data?.auth || null,
  }
}
