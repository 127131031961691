import {
  DatePicker,
  Flex,
  NumberInput,
  NumberInputProps,
  Select,
  Text,
  TextInput,
  TextInputProps,
  createForm,
} from '@applyboard/crystal-ui'
import { HasLanguageProficiencyCodes, TestType } from 'applications-types-lib'
import { isEmpty } from 'lodash'
import { IAdditionalDocumentDialog, ILanguageTestDocumentDialogForm } from '../types'
import {
  Subscores,
  documentTypeOptions,
  getLanguageProficiencyFieldsInfo,
  getSubscoresOrder,
  validateSubscore,
} from '../../../../utils'
import { FileUploadField, FileUploadFieldValue } from '../../ApplicationForms/FileUploadField'
import { DocumentDialog } from '../DocumentDialog'
import { ComponentType, useEffect, useRef, useState } from 'react'
import { useUpdateRequestedDocuments } from '../../../../hooks'
import { nanoid } from 'nanoid'
import { useApplicationFormContext } from '../../ApplicationForms/ApplicationForm'
import { ChangeLanguageTestDialog } from '../../ChangeLanguageTestDialog'
import { Asterisk } from '../../../Asterisk'

type LanguageTestDocumentsFormFields = {
  testType: string
  documents: FileUploadFieldValue[]
  certificateNumber: string
  testDate: string
  overallScore: string
  listening: string
  reading: string
  writing: string
  speaking: string
  description: string
}

const defaultValue = {
  testType: '',
  documents: [],
  testDate: '',
  certificateNumber: '',
  overallScore: '',
  listening: '',
  reading: '',
  speaking: '',
  writing: '',
  description: '',
}

const { Form, Field, useFieldValues, useResetForm, useSetFieldValues } =
  createForm<LanguageTestDocumentsFormFields>()

const UPLOAD_LIMIT = 1

export function LanguageTestDocumentsDialog(props: IAdditionalDocumentDialog) {
  const [open, setOpen] = useState(false)
  const { pendingFileUploadState, resetFiles } = useApplicationFormContext()
  const { isUpdatingApplication, updateRequestedDocuments } = useUpdateRequestedDocuments({
    applicationId: props.application.id,
    index: props.index,
  })

  const languageTestId = useRef<string>()
  useEffect(() => {
    languageTestId.current = nanoid()
  }, [])

  useEffect(() => {
    resetFiles({})
  }, [resetFiles])

  const requestedDocuments = props.application.attributes?.requestedDocuments?.[props.id]
  if (!requestedDocuments) {
    return null
  }

  return (
    <DocumentDialog
      document={props.document}
      heading={`Add ${documentTypeOptions?.[props.document.documentType]?.label}`}
      loading={isUpdatingApplication}
      open={open}
      setOpen={open => {
        resetFiles({})
        setOpen(open)
      }}
      form={`language-test-documents-${languageTestId}`}
    >
      <Flex direction="column" gap={4}>
        <Text contrast="mid">{props.document.note}</Text>
        <Form
          defaultValues={defaultValue}
          validationMode={'onSubmit'}
          onSubmit={data => {
            let languageData = {}
            if (data.testType === 'OTHER') {
              languageData = {
                testType: data.testType as TestType,
                description: data.description,
              }
            } else {
              languageData = {
                certificateNumber: data.certificateNumber,
                overallScore: data.overallScore,
                testDate: data.testDate.substring(0, 10),
                testType: data.testType as TestType,
                subscores: {
                  listening: parseFloat(data.listening),
                  reading: parseFloat(data.reading),
                  speaking: parseFloat(data.speaking),
                  writing: parseFloat(data.writing),
                },
              }
            }
            updateRequestedDocuments({
              additionalData: {
                languageProficiency: {
                  hasLanguageProficiency: 'HAVE' as HasLanguageProficiencyCodes,
                  languageProficiencyData: {
                    [languageTestId.current as string]: languageData,
                  },
                },
              },
              dataFiles: pendingFileUploadState,
              documentType: props.document.documentType,
              onSuccess: () => {
                setOpen(false)
              },
              requestedDocuments,
              requestedDocumentsId: props.id,
              sectionReference: languageTestId.current,
            })
          }}
          id={`language-test-documents-${languageTestId}`}
        >
          <LanguageTestDocumentsDialogForm
            languageTestId={languageTestId.current as string}
            application={props.application}
            document={props.document}
          />
        </Form>
      </Flex>
    </DocumentDialog>
  )
}

function LanguageTestDocumentsDialogForm(props: ILanguageTestDocumentDialogForm) {
  const { addPendingDelete, getObservableFiles } = useApplicationFormContext()

  const [dialogData, setDialogData] = useState<{
    currentTestValue: string
    isOpen: boolean
    newTestValue: string | null
  }>({
    currentTestValue: '',
    isOpen: false, // is the dialog open or closed
    newTestValue: null,
  })

  const {
    certificateNumber,
    documents,
    listening,
    overallScore,
    reading,
    speaking,
    testDate,
    testType,
    writing,
    description,
  } = useFieldValues([
    'certificateNumber',
    'documents',
    'listening',
    'overallScore',
    'reading',
    'speaking',
    'testDate',
    'testType',
    'writing',
    'description',
  ])
  const setFieldValues = useSetFieldValues()
  const resetForm = useResetForm()

  const testTypeOptions = {
    IELTS: 'IELTS',
    PTE: 'PTE Academic',
    TOEFL: 'TOEFL®️',
    OTHER: 'Other',
  }

  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  const subscoreOrder = getSubscoresOrder(testType)

  return (
    <Flex direction="column" gap={6}>
      <Flex.Item basis="100%">
        <Field
          as={Select}
          label="Proof type"
          name={`testType`}
          appearance="styled"
          required="Proof type is required"
          onChange={value => {
            if (
              testType.length !== 0 &&
              (!isEmpty(testDate) ||
                !isEmpty(certificateNumber) ||
                !isEmpty(documents) ||
                !isEmpty(overallScore) ||
                !isEmpty(listening) ||
                !isEmpty(reading) ||
                !isEmpty(speaking) ||
                !isEmpty(writing) ||
                !isEmpty(description))
            ) {
              setDialogData({
                currentTestValue: testType,
                isOpen: true,
                newTestValue: (value as string | undefined) || null,
              })
            }
          }}
        >
          {Object.entries(testTypeOptions).map(([value, label]) => (
            <Select.Option value={value} label={label} key={value} />
          ))}
        </Field>
      </Flex.Item>
      {fieldsInfo && subscoreOrder ? (
        <>
          <Field
            as={FileUploadField}
            allowedFileTypes={['.jpg', '.pdf', '.png', '.jpeg']}
            application={props.application}
            fileLimit={UPLOAD_LIMIT}
            fileType={fieldsInfo.fileType}
            label={
              <Text variant="bodyS" contrast="mid">
                Add your document below, supported file formats: JPG, JPEG, PDF, PNG, max number of
                files: {UPLOAD_LIMIT} <Asterisk />
              </Text>
            }
            name={`documents`}
            onRemove={(id: string) =>
              setFieldValues({ documents: documents.filter(file => file.id !== id) })
            }
            section={props.languageTestId}
            showHistory={false}
            validate={value => {
              if (!value.length) {
                return 'This field is required'
              }
              if (value.length > UPLOAD_LIMIT) {
                return `This field has a file limit of ${UPLOAD_LIMIT}.`
              }
              return true
            }}
          />
          <SelectTypeField />
          <SelectOtherTypeField />
        </>
      ) : null}
      <ChangeLanguageTestDialog
        currentTestLabel={
          testTypeOptions[dialogData.currentTestValue as keyof typeof testTypeOptions]
        }
        isOpen={dialogData.isOpen}
        newTestLabel={
          dialogData.newTestValue
            ? testTypeOptions[dialogData.newTestValue as keyof typeof testTypeOptions]
            : ''
        }
        onCloseDialog={(isOpen: boolean) => {
          setFieldValues({
            testType: dialogData.currentTestValue,
          })

          setDialogData({
            currentTestValue: '',
            isOpen: isOpen,
            newTestValue: null,
          })
        }}
        onConfirm={() => {
          const fieldsInfo = getLanguageProficiencyFieldsInfo(dialogData.currentTestValue)
          Object.keys(
            getObservableFiles({
              fileType: fieldsInfo?.fileType,
              sectionReference: props.languageTestId,
            }),
          ).forEach(id => {
            addPendingDelete(id)
          })

          resetForm()

          setFieldValues({
            ...defaultValue,
            testType: dialogData.newTestValue || '',
          })

          setDialogData({
            currentTestValue: '',
            isOpen: false,
            newTestValue: null,
          })
        }}
      />
    </Flex>
  )
}

type SubscoreFieldProps = {
  label: string
  name: Subscores
  min: number
  max: number
  validate: (value: string) => string | true
}

function SubscoreField(props: SubscoreFieldProps) {
  return (
    <Flex.Item basis="calc(50% - 8px)">
      <Field
        as={NumberInput as ComponentType<NumberInputProps>}
        label={props.label}
        name={props.name}
        max={props.max}
        min={props.min}
        required={`${props.label} is required`}
        validate={props.validate}
      />
    </Flex.Item>
  )
}

function SelectTypeField() {
  const { testType } = useFieldValues(['testType'])

  if (testType === 'OTHER') {
    return null
  }

  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  if (!fieldsInfo) {
    return null
  }

  const subscoreOrder = getSubscoresOrder(testType)

  const maxDate = new Date()
  const minDate = new Date()

  minDate.setFullYear(minDate.getFullYear() - 50)

  return (
    <>
      <Flex basis="100%" gap={4} direction="row" wrap>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={DatePicker}
            label="Test date"
            name="testDate"
            maxDate={maxDate.toISOString()}
            minDate={minDate.toISOString()}
            required="Test date is required"
          />
        </Flex.Item>
        <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
          <Field
            as={TextInput as ComponentType<TextInputProps>}
            label={fieldsInfo.codeLabel}
            name="certificateNumber"
            required={`${fieldsInfo.codeLabel} is required`}
          />
        </Flex.Item>
        <Flex.Item basis="calc(50% - 8px)">
          <Field
            as={NumberInput as ComponentType<NumberInputProps>}
            label="Overall score"
            name="overallScore"
            max={fieldsInfo.maxOverall}
            min={fieldsInfo.minOverall}
            required="Overall score is required"
            validate={data => {
              if (
                parseInt(data) < (fieldsInfo.minOverall || 0) ||
                parseInt(data) > (fieldsInfo.maxOverall || 0)
              ) {
                return 'Invalid score'
              }

              return true
            }}
          />
        </Flex.Item>
        <Flex.Item>{null}</Flex.Item>
      </Flex>
      <Flex basis="100%" gap={4} direction="row" wrap>
        {subscoreOrder.map(subscore => (
          <SubscoreField
            key={subscore.name}
            label={subscore.label}
            name={subscore.name}
            max={fieldsInfo.maxSubscore ?? 0}
            min={fieldsInfo.minSubscore ?? 0}
            validate={data => {
              return validateSubscore(testType, parseInt(data))
            }}
          />
        ))}
      </Flex>
    </>
  )
}

function SelectOtherTypeField() {
  const { testType } = useFieldValues(['testType'])
  if (testType !== 'OTHER') {
    return null
  }
  return (
    <Flex basis="100%" gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
      <Flex.Item basis={{ xs: '100%' }}>
        <Field
          as={TextInput as ComponentType<TextInputProps>}
          label="Please specify other proof type"
          name="description"
          helpText="Provide a description with up to 200 characters"
          validate={value => {
            if (value.length > 200) {
              return 'This field has a character limit of 200'
            }
            return true
          }}
          required={'This field is required'}
        />
      </Flex.Item>
    </Flex>
  )
}
