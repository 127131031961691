import { StudentApplication } from '../components/Application/types'
import { useMemo } from 'react'
import { ApplicationState } from './useListApplications'
import { z } from 'zod'
import {
  DocumentTags,
  EducationLevel,
  LanguageExamAvailability,
  RelationshipType,
  TimelineTabs,
} from '../utils/enums'

export type TimelineObject = {
  key: TimelineTabs
  label: string
  step: number
  cardNumber: number
  icon: string
  title: string
  formId: string
  completed: boolean
}

const timelineItems: TimelineObject[] = [
  {
    key: TimelineTabs.PERSONAL_INFORMATION,
    label: 'Personal Information',
    step: 1,
    cardNumber: 1,
    icon: '👤',
    title: 'Personal Information',
    formId: crypto.randomUUID(),
    completed: false,
  },
  {
    key: TimelineTabs.CONTACT_INFORMATION,
    label: 'Contact Information',
    step: 2,
    cardNumber: 2,
    icon: '☎️',
    title: 'Contact Information',
    formId: crypto.randomUUID(),
    completed: false,
  },
  {
    key: TimelineTabs.EDUCATION_HISTORY,
    label: 'Education History',
    step: 3,
    cardNumber: 3,
    icon: '📚',
    title: 'Education History',
    formId: crypto.randomUUID(),
    completed: false,
  },
  {
    key: TimelineTabs.LANGUAGE_PROFICIENCY,
    label: 'Language Proficiency',
    step: 4,
    cardNumber: 4,
    icon: '🗣️',
    title: 'Language Proficiency',
    formId: crypto.randomUUID(),
    completed: false,
  },
  {
    key: TimelineTabs.OTHER_REQUIRED_DOCUMENTS,
    label: 'Other Required Documents',
    step: 5,
    cardNumber: 5,
    icon: '📄',
    title: 'Other Required Documents',
    formId: crypto.randomUUID(),
    completed: false,
  },
]

export function useGetTimelineItems(application: StudentApplication | null): TimelineObject[] {
  return useMemo(() => {
    if (!application) return timelineItems

    return timelineItems.map((timelineItem: TimelineObject, index) => {
      return {
        ...timelineItem,
        completed: isTabCompleted(timelineItem, application),
      }
    })
  }, [application])
}

export function isTabCompleted(timeLineItem: TimelineObject, application: StudentApplication) {
  if (!application) {
    return false
  }

  if (application.attributes?.applicationState !== ApplicationState.DRAFT) {
    return true
  }

  switch (timeLineItem.key) {
    case TimelineTabs.PERSONAL_INFORMATION: {
      const personalInformationSchema = z.object({
        basicPersonalInformation: z.object({
          givenName: z.string().min(1),
          gender: z.string(),
          dateOfBirth: z.coerce.date().max(new Date()),
          nationality: z.string(),
        }),
        addresses: z.object({
          residentialAddress: z.object({
            country: z.string(),
            addressLine1: z.string().min(1),
            province: z.string().min(1),
            city: z.string().min(1),
          }),
          mailingAddress: z.object({
            country: z.string(),
            addressLine1: z.string().min(1),
            province: z.string().min(1),
            city: z.string().min(1),
          }),
        }),
      })
      const statusAndCitizenshipSchema = z.object({
        statusAndCitizenship: z.object({
          passports: z.record(
            z.object({
              passportExpiry: z.coerce.date(),
              passportNumber: z.string().min(1),
            }),
          ),
          destinationCountryStatus: z.string(),
        }),
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
              activeRecord: z.boolean(),
            }),
          )
          .refine(data => {
            return Object.values(data).some(file => file.type === DocumentTags.PASSPORT)
          }),
      })

      const destinationCountryFileSchema = z
        .record(
          z.object({
            type: z.nativeEnum(DocumentTags),
            uploadStatus: z.string().optional(),
          }),
        )
        .refine(data => {
          return Object.values(data).some(
            file => file.type === DocumentTags.DESTINATION_COUNTRY_STATUS,
          )
        })
      return (
        personalInformationSchema.safeParse(application.attributes.personalInformation).success &&
        statusAndCitizenshipSchema.safeParse(application.attributes).success &&
        (application.attributes.statusAndCitizenship?.destinationCountryStatus === 'NO_STATUS' ||
          destinationCountryFileSchema.safeParse(application?.attributes?.files).success)
      )
    }

    case TimelineTabs.CONTACT_INFORMATION: {
      const contactInformationSchema = z.object({
        contactInformation: z.object({
          phoneNumber: z.string().min(1),
        }),
        emergencyContactSchema: z.union([
          z.object({
            email: z.string().email(),
            name: z.string().min(1),
            phoneNumber: z.string().min(1),
            relationship: z.nativeEnum(RelationshipType),
          }),
          z.undefined(),
        ]),
      })

      return contactInformationSchema.safeParse(application.attributes.personalInformation).success
    }

    case TimelineTabs.EDUCATION_HISTORY: {
      const educationHistorySchema = z.object({
        educationHistory: z.record(
          z.object({
            educationLevel: z.nativeEnum(EducationLevel),
            educationCountry: z.string(),
            credentialReceived: z.string().min(1),
            startDate: z.coerce.date(),
            endDate: z.coerce.date(),
          }),
        ),
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            return Object.values(data).some(
              file =>
                file.type === DocumentTags.TRANSCRIPTS || file.type === DocumentTags.CERTIFICATE,
            )
          }),
      })

      return educationHistorySchema.safeParse(application.attributes).success
    }

    case TimelineTabs.LANGUAGE_PROFICIENCY: {
      const defaultLanguageProficiencySchema = z.object({
        hasLanguageProficiency: z.enum([
          LanguageExamAvailability.DONT_NEED,
          LanguageExamAvailability.WILL_HAVE,
        ]),
      })

      const proofTypeOther = z.object({
        testType: z.literal('OTHER'),
        description: z.string().max(200),
      })

      const haveLanguageProficiencySchema = z.object({
        hasLanguageProficiency: z.literal(LanguageExamAvailability.HAVE),
        languageProficiencyData: z.record(
          z.discriminatedUnion('testType', [
            proofTypeOther,
            z.object({
              certificateNumber: z.string().min(1),
              testDate: z.coerce.date(),
              testType: z.enum(['IELTS', 'PTE', 'TOEFL']),
              overallScore: z.coerce.number(),
              subscores: z.object({
                listening: z.number(),
                reading: z.number(),
                speaking: z.number(),
                writing: z.number(),
              }),
            }),
          ]),
        ),
      })

      const languageProficiencySchema = z.object({
        languageProficiency: z.discriminatedUnion('hasLanguageProficiency', [
          defaultLanguageProficiencySchema,
          haveLanguageProficiencySchema,
        ]),
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            if (
              application?.attributes?.languageProficiency?.hasLanguageProficiency &&
              (application?.attributes?.languageProficiency?.hasLanguageProficiency as string) !==
                LanguageExamAvailability.HAVE
            ) {
              return true
            }

            return Object.values(data).some(
              file =>
                file.type === DocumentTags.LANGUAGE_TEST_IELTS ||
                file.type === DocumentTags.LANGUAGE_TEST_PTE ||
                file.type === DocumentTags.LANGUAGE_TEST_TOEFL ||
                file.type === DocumentTags.LANGUAGE_TEST_OTHER,
            )
          }),
      })

      return languageProficiencySchema.safeParse(application.attributes).success
    }

    case TimelineTabs.OTHER_REQUIRED_DOCUMENTS: {
      const additionalDocumentsFileSchema = z.object({
        files: z
          .record(
            z.object({
              type: z.nativeEnum(DocumentTags),
              uploadStatus: z.string().optional(),
            }),
          )
          .refine(data => {
            return Object.values(data).some(
              file =>
                file.type === DocumentTags.ADDITIONAL_DOCUMENT ||
                file.type === DocumentTags.STATEMENT_OF_PURPOSE ||
                file.type === DocumentTags.LETTER_OF_RECOMMENDATION ||
                file.type === DocumentTags.CONSENT_FORM ||
                file.type === DocumentTags.GMAT_SCORE ||
                file.type === DocumentTags.GRE_SCORE ||
                file.type === DocumentTags.RESUME ||
                file.type === DocumentTags.EMPLOYMENT_LETTER ||
                file.type === DocumentTags.VOLUNTEER_LETTER,
            )
          }),
      })

      return additionalDocumentsFileSchema.safeParse(application.attributes).success
    }
  }
}
